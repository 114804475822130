.AutoCompleteText {
  /* list-style: none; */
  width: 100%;
  color: red;
  border: 1px solid gray;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
}

.AutoCompleteText input {
  /* list-style: none; */
  width: 100%;
  border: none;

  font-family: Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
  padding: 10px 5px;

  box-sizing: border-box;
  outline: none;
}

.AutoCompleteText ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border-top: 1px solid gray;
}

.AutoCompleteText ul::before {
  content: "";
}

.AutoCompleteText li {
  padding: 10px 5px;
  cursor: pointer;
}

.AutoCompleteText li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}
