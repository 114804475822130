.jumbotron {
  margin-bottom: 0px;
  background-image: url(./img/OG0AX103.jpg);
  background-position: 0% 70%;
  background-size: cover;
  background-repeat: no-repeat;
  /* color: white; */
  text-shadow: black 0.3em 0.3em 0.3em;
}

.myJumbotron {
  margin-bottom: 0px;
  background-image: url(./img/OG0AX103.jpg);
  background-position: 0% 70%;
  background-size: cover;
  background-repeat: no-repeat;
  /* color: white; */
  text-shadow: black 0.3em 0.3em 0.3em;
}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}
.abs2-center {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 20vh;
}

.abs0-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.in-center {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  min-height: 10vh;
}

.bubble {
  text-align: center;
}

.text-mySize {
  font-size: 96px;
}

.onImageText {
  color: white;
  /* background: #2c3e50; */
  background: rgba(62, 63, 58, 0.4);
  /* background: rgba(147, 197, 75, 0.4); */
  /* background: rgba(24, 188, 156, 0.4); */
  /* background: rgba(60, 88, 72, 0.658); */
}

.myButton {
  cursor: pointer;
}

.myWhite {
  background-color: white;
  color: white;
}

.myTd {
  text-align: center;
}

.myMervadoPagoButton {
  height: 45;
}

.myMercadoPagoButton {
  background-color: #009ee3;
  color: #ffffff;

  border: 1px solid #009ee3;
  border-radius: 45;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 45px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.imgservice {
  text-anchor: middle;
  width: 18rem;
}

.App-Component {
  margin: 150px auto 0 auto;
  width: 600px;
}
